<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text
        class="font-small-2 mr-25 mb-0"
        :class="loading ? 'text-primary' : ''"
      >
        {{ loading ? 'Updating...' : 'Updated Just now' }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in sData"
          :key="index"
          xl="4"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { kFormatter } from '@/@core/utils/filter'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  mixins: [AuthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sData: [],
    }
  },
  watch: {
    data(v) {
      if (this.isAdmin) this.sData[0].title = kFormatter(v.totalRevenue.totalAmount || 0)
      this.sData[this.isAdmin ? 1 : 0].title = kFormatter(v.allCreditCount.length || 0)
      this.sData[this.isAdmin ? 2 : 1].title = kFormatter(v.toBeExpireDateItemsCount || 0)
    },
  },
  mounted() {
    this.sData = [{
      customClass: '',
      color: 'danger',
      icon: 'DollarSignIcon',
      title: '0',
      subtitle: 'PT credit',

    }, {
      customClass: '',
      color: 'warning',
      icon: 'CalendarIcon',
      title: '0',
      subtitle: 'To be expired',

    },
    ]
    if (this.isAdmin) {
      this.sData.splice(0, 0, {
        customClass: '',
        color: 'success',
        icon: 'TrendingUpIcon',
        title: '0',
        subtitle: 'Sales',

      })
    }
  },
}
</script>
