<template>
  <b-card
    class="earnings-card"
  >
    <b-card-title class="mb-1">
      Low Stock Reminder
    </b-card-title>
    <b-card-body style="    padding: 0px;">
      <b-list-group>
        <b-list-group-item
          v-if="isAdmin"
          class="d-flex justify-content-between align-items-center"
          button
          @click="$router.push({name: 'low-in', query: {type: 'stock'}})"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="TrendingDownIcon"
                size="15"
                class="text-danger"
              />
            </span>

            <span>Low in stock</span>
          </div>

          <span class="ml-1">
            <span class="text-danger text-bold">{{ data.lowInStockCount }} </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="15"
            />
          </span>

        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          button
          @click="$router.push({name: 'low-in', query: {type: 'store'}})"
        >
          <div>
            <span class="mr-1">
              <feather-icon
                icon="TrendingDownIcon"
                size="15"
                class="text-danger"
              />
            </span>
            <span>Low in shop</span>

          </div>

          <span class="ml-1">
            <span class="text-danger text-bold">{{ data.lowInStoreCount }} </span>
            <feather-icon
              icon="ChevronRightIcon"
              size="15"
            />
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BListGroup,
    BListGroupItem,
  },
  mixins: [AuthMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
}
</script>
