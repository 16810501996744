<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="5"
        md="6"
      >
        <ecommerce-medal
          :loading="loadingHomepage"
          :data="homePageModel"
        />
      </b-col>
      <b-col
        xl="7"
        md="6"
      >
        <ecommerce-statistics
          :loading="loadingHomepage"
          :data="homePageModel"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="5">
        <b-row class="match-height">
          <b-col
            v-if="isAdmin"
            lg="6"
            md="3"
            cols="6"
          >
            <statistic-card-with-area-chart
              icon="UsersIcon"
              color="danger"
              :statistic="kFormatter(homePageModel.totalRevenue ? homePageModel.totalRevenue.totalSale : 0)"
              statistic-title="Total Qty sold"
              :chart-data="subscribersGained.series"
              :statistic-sub-title="kFormatter(homePageModel.totalRevenue ? homePageModel.totalRevenue.totalSaleQty : 0)"
            />
          </b-col>
          <b-col
            v-if="isAdmin"
            lg="6"
            md="3"
            cols="6"
          >
            <statistic-card-with-area-chart
              icon="UsersIcon"
              color="success"
              :statistic="kFormatter(homePageModel.totalRevenue ? homePageModel.totalRevenue.totalStockPurchase : 0)"
              statistic-title="Total Qty available"
              :statistic-sub-title="kFormatter(homePageModel.totalRevenue ? homePageModel.totalRevenue.stockQty : 0)"
              :chart-data="subscribersGained.series"
            />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <b-overlay
              :show="true"
            >
              <h6 slot="overlay">
                Coming soon
              </h6>
              <ecommerce-earnings-chart :data="homePageModel" />
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        v-if="isAdmin"
        lg="7"
      >
        <!-- <b-overlay
          :show="true"
        >
          <h6 slot="overlay">
            Coming soon
          </h6> -->
        <ecommerce-revenue-report :data="homePageModel" />
        <!-- </b-overlay> -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import {
  Ajax, getApiData, isApiSuccess, RouteConst,
} from '@/libs/ajax'
// import { getApiData, isApiSuccess } from '@/api/ajax'
// import GeneralCtr from '@/api/general_ctr'
import EcommerceMedal from './components/EcommerceMedal.vue'
import EcommerceStatistics from './components/EcommerceStatistics.vue'
import EcommerceRevenueReport from './components/EcommerceRevenueReport.vue'
import EcommerceEarningsChart from './components/EcommerceEarningsChart.vue'
import AuthMixin from '../../mixins/authMixin'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    StatisticCardWithAreaChart,
    EcommerceEarningsChart,
  },
  mixins: [AuthMixin],
  data() {
    return {
      loadingHomepage: false,
      homePageModel: {},
      subscribersGained: { series: [{ name: 'Subscribers', data: [28, 40, 36, 52, 38, 60, 55] }], analyticsData: { subscribers: 92600 } },
    }
  },
  mounted() {
    this.handleGetHomepageData()
  },
  methods: {
    kFormatter,
    async handleGetHomepageData() {
      this.loadingHomepage = true

      const userId = null
      const version = null
      const isFromRefresh = true
      const previousUser = {}
      const currentUser = this.$store.state.auth.AppActiveUser

      try {
        const res = await Ajax(RouteConst.getHomepageDataUrl, {
          body: {
            userId,
            // 'token': token,
            version,
            isFromRefresh,
            previousUser,
            currentUser,
          },
        })
        if (isApiSuccess(res)) {
          // console.debug(getApiData(res))
          this.homePageModel = getApiData(res).homeData
        }
      } catch (error) {
        console.debug(error)
      }
      this.loadingHomepage = false
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
