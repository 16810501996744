<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          Please wait...
        </p>
      </div>
    </template>
    <b-card
      class="card-developer-meetup"
    >
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{ dayName }}
          </h6>
          <h3 class="mb-0">
            {{ day }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            Hello {{ companyInfo ? companyInfo.name : '' }}
          </b-card-title>
          <b-card-text class="mb-0">
            Look to your dashboard
          </b-card-text>
        </div>
      </div>

      <!-- <barcode
        id="hello-1213"
        :value="`182309`"
        font-options="bold"
      >
        Can't generate the barcode
      </barcode> -->

      <h3 class="mb-75 mt-2 pt-50">
        <!-- <b-link>${{ kFormatter(39023) }}</b-link> -->
        <b-link />
      </h3>
      <div style="height: 40px;">
        &nbsp;
      </div>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="downloadSvg"
      >
        Download
      </b-button> -->

    </b-card>

  </b-overlay>
</template>

<script>
// import VueBarcode from 'vue-barcode'

import {
  BCard, BCardText,
  BLink,
  BCardTitle,
  // BButton,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BCard,
    BOverlay,
    // barcode: VueBarcode,
    // BButton,
    BCardText,
    BCardTitle,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dayName: '',
      day: '',
    }
  },
  mounted() {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dayName = days[new Date().getDay()]
    this.dayName = dayName.toUpperCase()
    this.day = new Date().getDate()
  },
  methods: {
    kFormatter,
    downloadSvg() {
      import('@/libs/svgToPng').then(svg => {
        // console.debug('done')
        // console.debug(svg)
        const z = document.getElementById('hello-1213')
        // console.debug(z)
        svg.svgToPng(z.getInnerHTML())
      })
    },
  },
}
</script>
